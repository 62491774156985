
const t = {
    icon: 'fa-file',
    display: {
        properties: [
            'id','code', 'date', 'employeeFirstName','tenantName', 'externalId', ['mainFile']
        ],
    },
    list: {
        props: {
            search: false,
            searchSwitch: false,
        },
        icon: 'fa-toolbox',
        columns: [
            {id: 'date', label: 'column_date_label', format:(v: any) => v ? new Date(parseInt(v)).toLocaleDateString('FR-fr',{ year: 'numeric', month: 'long', day: 'numeric',}) : 'undefined'},
            {id: 'mainFile', label: 'column_mainFile_label'},
            {id: 'externalId', label: 'column_externalid_label'},
            
        ],
        actions: ['display', 'download'],
        //actions: ['display', 'edit', 'delete', 'go_public_page', 'download'],
    },
};

export default t;