const t = {
    icon: 'fa-file',
    menus: {
        main: {section: 'files', priority: 30},
    },
    display: {
        properties: [
            'type','dateStart','range'
        ],
    },
    list: {
        props: {
            search: false,
            searchSwitch: false,
        },
        icon: 'fa-clock',
        columns: [
            {id: 'type', label: 'column_type_label'},
            {id: 'dateStart', label: 'column_datestart_label',format:  (v: any) => v ? new Date(parseInt(v)).toLocaleDateString() : 'undefined'},
            {id: 'range', label: 'column_range_label',format:  (v: any) => v ? `${new Date(parseInt(v.split('-')[0])).toLocaleDateString()} - ${new Date(parseInt(v.split('-')[1])).toLocaleDateString()}` : 'undefined'},
        ],
        globalActions: ['add'],
        actions: ['display'],
    },
};

export default t;