// noinspection JSUnusedGlobalSymbols

export const LOGIN = (gql: any) => gql`
    mutation login($data: CreateAuthTokenInput!) {
        createAuthToken(data: $data) {
            token
            refreshToken
        }
    }
`;
export const REFRESH_LOGIN = (gql: any) => gql`
    mutation refreshLogin($data: RefreshAuthTokenInput!) {
        refreshAuthToken(data: $data) {
            token
            refreshToken
        }
    }
`;
export const CREATE_BUSINESS_DAY = (gql: any) => gql`
    mutation createBusinessDay($data: CreateBusinessDayInput!) {
        createBusinessDay(data: $data) {
            id
        }
    }
`
export const CREATE_NOTIFICATION = (gql: any) => gql`
    mutation createNotification($data: CreateNotificationInput!) {
        createNotification(data: $data) {
            id
        }
    }
`;
export const CREATE_USER = (gql: any) => gql`
    mutation createUser($data: CreateUserInput!) {
        createUser(data: $data) {
            id
        }
    }
`;
export const CREATE_EMPLOYEE = (gql: any) => gql`
    mutation createEmployee($data: CreateEmployeeInput!) {
        createEmployee(data: $data) {
            id
        }
    }
`;
export const CREATE_PERIOD = (gql: any) => gql`
    mutation createPeriod($data: CreateEperiodInput!) {
        createPeriod(data: $data) {
            id
        }
    }
`;
export const CREATE_EINVOICE = (gql: any) => gql`
    mutation createEinvoice($data: CreateEinvoiceInput!) {
        createEinvoice(data: $data) {
            id
        }
    }
`;
export const CREATE_REPORT = (gql: any) => gql`
    mutation createReport($data: CreateReportInput!) {
        createReport(data: $data) {
            id
        }
    }
`;
export const CREATE_CUSTOMER = (gql: any) => gql`
    mutation createCustomer($data: CreateCustomerInput!) {
        createCustomer(data: $data) {
            id
        }
    }
`;
export const CREATE_CONTRACT = (gql: any) => gql`
    mutation createContract($data: CreateContractInput!) {
        createContract(data: $data) {
            id
        }
    }
`;
export const CREATE_SIMULATION = (gql: any) => gql`
    mutation createSimulation($data: CreateSimulationInput!) {
        createSimulation(data: $data) {
            id
        }
    }
`;
export const CREATE_PROJECT = (gql: any) => gql`
    mutation createProject($data: CreateProjectInput!) {
        createProject(data: $data) {
            id
        }
    }
`;
export const CREATE_CINVOICE = (gql: any) => gql`
    mutation createCinvoice($data: CreateCinvoiceInput!) {
        createCinvoice(data: $data) {
            id
        }
    }
`;
export const CREATE_CPERIOD = (gql: any) => gql`
    mutation createCperiod($data: CreateCperiodInput!) {
        createCperiod(data: $data) {
            id
        }
    }
`;
export const CREATE_CPAYMENT = (gql: any) => gql`
    mutation createCpayment($data: CreateCpaymentInput!) {
        createCpayment(data: $data) {
            id
        }
    }
`;
export const CREATE_PAYSLIP = (gql: any) => gql`
    mutation createPayslip($data: CreatePayslipInput!) {
        createPayslip(data: $data) {
            id
        }
    }
`;

export const GET_CURRENT_USER = (gql: any) => gql`
    query getCurrentUser {
        getCurrentUser {
            id
            email
            firstName
            lastName
            createdAt
            updatedAt
            status
            role
        }
    }
`;
export const GET_NOTIFICATION = (gql: any) => gql`
    query getNotification($id: ID!) {
        getNotification(id: $id) {
            id
            createdAt
            status
            tenant
            tenantCode
            tenantName
            organization
            organizationCode
            organizationName
            template
            recipient
            sender
            variables
            type
            locale
            country
            subject
            attachments
            bodyFile {
                available
                url
            }
            bodyTextFile {
                available
                url
            }
        }
    }
`;
export const GET_ORGANIZATION = (gql: any) => gql`
    query getOrganization($id: ID!) {
        getOrganization(id: $id) {
            id
            name
            fullName
            shortName
            legalName
            createdAt
            updatedAt
            status
            code
            locale
            country
            city
            street
            zipCode
            overline
            complement
            state
            owner
            ownerEmail
            ownerFirstName
            ownerLastName
            tenant
            faviconImage {
                available
                url
            }
            appleTouchImage {
                available
                url
            }
            logoImage {
                available
                url
            }
            logoAltImage {
                available
                url
                
            }
            bannerImage {
                available
                url
            }
            mainImage {
                available
                url
            }
            websiteUrl
            twitterUrl
            facebookUrl
            instagramUrl
            linkedinUrl
            youtubeUrl
            pinterestUrl
            tumblrUrl
            flickrUrl
            redditUrl
            tiktokUrl
            vimeoUrl
            snapchatUrl
        }
    }
`;
export const GET_TENANT = (gql: any) => gql`
    query getTenant($id: ID!) {
        getTenant(id: $id) {
            id
            code
            name
        }
    }
`;
export const GET_USER = (gql: any) => gql`
    query getUser($id: ID!) {
        getUser(id: $id) {
            id
            email
            firstName
            lastName
            createdAt
            updatedAt
            status
            role
        }
    }
`;
export const GET_EMPLOYEE = (gql: any) => gql`
    query getEmployee {
        getEmployee {
            id
            code
            tenant
            email
            firstName
            externalId
            lastName
            createdAt
            updatedAt
            status
            statAccountAmount
            statGlobalBilledAmount
            statExpensesOfMonth
        }
    }
`;
export const GET_EMPLOYEE_STATS = (gql: any) => gql`
    query getEmployeeStats {
        getEmployeeStats {
            statAccountAmount
            statGlobalBilledAmount
            statExpenseOfMonth
            statExpensesOfYear
            statExpenseForecast
            statIncomeOfMonth
            statIncomesOfYear
            statIncomeForecast
            statPeeByMonth
            statPerByMonth
            statSavingsByMonth
        }
    }
`;

export const GET_BUSINESSDAY = (gql: any) => gql`
    query getBusinessDay($id: ID!) {
        getBusinessDay(id: $id) {
            id 
            tenant
            employee
            type
            range
            dateStart
        }
    }
`;

export const GET_PERIOD = (gql: any) => gql`
    query getPeriod($id: ID!) {
        getPeriod(id: $id) {
            dateStart
            dateEnd
            contract
            contractName
            contractRate
            project
            projectCode
            customerName
            workedDay
            periodPercent
            periodTurnover
            internalFees
            periodExpense
            periodChargeProvided
            baseAccount
            taxedSalary
            employerContributions
            employeeContributions
            salary
            peeAmount
            perAmount
            incomeTaxes
            commercialFee
            craUrl
            payslipUrl
            expenses {
                    id
                    tenant
                    employee
                    period
                    amount
                    desc
                    cost
                    name
                    label
                    costVAT
                    vatCost
                    vatPercent
                    mooncardId
                    # date: DateTime
                    date 
                    justify
                    display
                    externalId
                }
                earnings {
                    amount
                    label
                }
                bills {
                    id
                    clientName
                    price
                    desc
                    fileUrl
                }  
        }
    }
`;
export const GET_EINVOICE = (gql: any) => gql`
    query getEinvoice($id: ID!) {
        getEinvoice(id: $id) {
            id
            project
            customer
            externalId
            tenant
        }
    }
`;
export const GET_REPORT = (gql: any) => gql`
    query getReport($id: ID!) {
        getReport(id: $id) {
         id
         tenant
        }
    }
`;

export const GET_EXPENSE = (gql: any) => gql`
    query getExpense($id: ID!) {
        getExpense(id: $id) {
         id
         tenant
         name
         cost
         vatCost
         vatPercent
         mooncardId
         date
         justify
         display
        }
    }
`;

export const GET_PAYSLIP = (gql: any) => gql`
    query getPayslip($id: ID!) {
        getPayslip(id: $id) {
            id
            code
            date
            tenant
            employee
            employeeEmail
            employeeFirstName
            employeeLastName
            employeePhone
            externalId
            mainFile {
                available
                url
            }
        }
    }
`;
export const GET_CUSTOMER = (gql: any) => gql`
    query getCustomer($id: ID!) {
        getCustomer(id: $id) {
            id
            code
            tenant
            name
        }
    }
`;
export const GET_CONTRACT = (gql: any) => gql`
    query getContract($id: ID!) {
        getContract(id: $id) {
            id
            code
            tenant
            employee
            customer
            code
            rate
            project
            locked
            billingMode
            is_amendment
        }
    }
`;
export const GET_SIMULATION = (gql: any) => gql`
    query getSimulation($id: ID!) {
        getSimulation(id: $id) {
            id
            type
            result
            values
            tenant
            ownerEmail
            ownerName
            ownerFirstName
            date
            createdAt
            status
            failureDetails
        }
    }
`;
export const GET_PROJECT = (gql: any) => gql`
    query getProject($id: ID!) {
        getProject(id: $id) {
            id
            tenant
            customer
            code
        }
    }
`;
export const GET_CINVOICE = (gql: any) => gql`
    query getCinvoice($id: ID!) {
        getCinvoice(id: $id) {
            id
            project
            customer
            externalId
            tenant
        }
    }
`;
export const GET_CPERIOD = (gql: any) => gql`
    query getCperiod($id: ID!) {
        getCperiod(id: $id) {
            id
            createdAt
            updatedAt
            project
            customer 
            tenant 
          
        }
    }
`;
export const GET_CPAYMENT = (gql: any) => gql`
    query getCpayment($id: ID!) {
        getCpayment(id: $id) {
            id
            createdAt
            updatedAt
            project
            customer 
            tenant  
        }
    }
`;

export const FIND_BUSINESSDAYS = (gql: any) => gql`
    query findBusinessDays($offset: String, $limit: Int) {
        findBusinessDays(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                tenant
                employee
                type
                range
                dateStart
            }
        }
    }
`;

export const GET_ORGANIZATION_BY_CODE = (gql: any) => gql`
    query getOrganizationByCode($code: String!) {
        getOrganizationByCode(code: $code) {
            id
            code
            name
        }
    }
`;

export const FIND_NOTIFICATIONS = (gql: any) => gql`
    query findNotifications($offset: String, $limit: Int) {
        findNotifications(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                createdAt
                status
                tenant
                tenantCode
                tenantName
                organization
                organizationCode
                organizationName
                template
                recipient
                sender
                variables
                type
                locale
                country
                subject
                attachments
                bodyFile {
                    available
                    url
                }
                bodyTextFile {
                    available
                    url
                }
            }
        }
    }
`;
export const FIND_ORGANIZATIONS = (gql: any) => gql`
    query findOrganizations($offset: String, $limit: Int) {
        findOrganizations(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                tenant
                tenantCode
                name
                createdAt
                updatedAt
                status
                mail_notification_paye
            }
        }
    }
`;
export const FIND_TENANTS = (gql: any) => gql`
    query findTenants($offset: String, $limit: Int) {
        findTenants(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                name
            }
        }
    }
`;
export const FIND_USERS = (gql: any) => gql`
    query findUsers($offset: String, $limit: Int) {
        findUsers(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                lastName
                firstName
                email
                createdAt
                updatedAt
                status
                role
            }
        }
    }
`;
export const FIND_EMPLOYEES = (gql: any) => gql`
    query findEmployees($offset: String, $limit: Int) {
        findEmployees(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                lastName
                firstName
                externalId
                email
                createdAt
                updatedAt
                status
                tenant
                statAccountAmount
            }
        }
    }
`;
export const FIND_PERIODS = (gql: any) => gql`
    query findPeriods($offset: String, $limit: Int,$sortField: String, $sortOrder: String, $year: Int) {
        findPeriods(offset: $offset, limit: $limit, sortField: $sortField, sortOrder:$sortOrder, year:$year) {
            cursor
            count
            items {
                id
                contract
                contractName
                customerName
                dateStart
                dateEnd
                workedDay
                periodPercent
                periodTurnover
                internalFees
                periodExpense
                periodChargeProvided
                baseAccount
                taxedSalary
                employerContributions
                employeeContributions
                salary
                peeAmount
                perAmount
                project
                incomeTaxes
                commercialFee
                craUrl
                payslipUrl
                
            }
        }
    }
`;





export const FIND_EINVOICES = (gql: any) => gql`
    query findEinvoices($offset: String, $limit: Int) {
        findEinvoices(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                tenant
                tenantCode
                tenantName
                customer
                customerCode
                customerName
                project
                projectCode
                projectName
                price
                description
                externalId
            }
        }
    }
`;
export const FIND_REPORTS = (gql: any) => gql`
    query findReports($offset: String, $limit: Int) {
        findReports(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                contract
                month
                workedDay
                mainFile {
                    url
                }
            }
        }
    }
`;

export const FIND_EXPENSES = (gql: any) => gql`
    query findExpenses($offset: String, $limit: Int) {
        findExpenses(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                tenant
                name
                cost
                desc
                vatCost
                vatPercent
                mooncardId
                date
                justify
                display
                createdAt
            }
        }
    }
`;

export const FIND_FILES = (gql: any) => gql`
    query findFiles($type: String,$offset: String, $limit: Int) {
        findFiles(type: $type, offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                tenant
                contract
                contractName
                workedDay
                month
                employee
                type
                name
                mainFile {
                    url
                    available
                    size
                }
            }
        }
    }
`;

export const FIND_CUSTOMERS = (gql: any) => gql`
    query findCustomers($offset: String, $limit: Int) {
        findCustomers(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                code
                name
                tenant
                externalId
            }
        }
    }
`;

export const FIND_CONTRACTS = (gql: any) => gql`
    query findContracts($offset: String, $limit: Int,$sortField: String, $sortOrder: String,$filter: ContractFilter) {
        findContracts(offset: $offset, limit: $limit,sortField: $sortField, sortOrder: $sortOrder, filter: $filter) {
            cursor
            count
            items {
                id
                code
                customer
                customerName
                rate
                locked
                billingMode
                is_amendment
                dateEnd
                dateStart
                status
            }
        }
    }
`;
export const FIND_SIMULATIONS = (gql: any) => gql`
    query findSimulations($offset: String, $limit: Int) {
        findSimulations(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                type
                result
                values
                tenant
                tenantCode
                tenantName
                ownerEmail
                ownerName
                ownerFirstName
                createdAt
                status
            }
        }
    }
`;

export const FIND_STATEMENT_VALUES = (gql: any) => gql`
    query findStatementValues($offset: String, $limit: Int,$sortField: String, $sortOrder: String, $year: Int) {
        findStatementValues(offset: $offset, limit: $limit,sortField: $sortField, sortOrder: $sortOrder, year: $year) {
            cursor
            count
            items {
                id
                period
                contractName
                contractRate
                customerName
                contractEndCustomerName
                periodPercent
                workedDay
                periodTurnover
                gwFee
                periodExpense
                internalFees
                commercialFee
                baseAccount
                employerContributions
                employeeContributions
                taxedSalary
                incomeTaxes
                salary
                peeAmount
                perAmount
                downloadUrl
                expenses {
                    id
                    tenant
                    employee
                    period
                    amount
                    desc
                    cost
                    name
                    label
                    costVAT
                    vatCost
                    vatPercent
                    mooncardId
                    date 
                    justify
                    display
                    externalId
                }
                earnings {
                    amount
                    label
                }
                bills {
                    id
                    clientName
                    price
                    desc
                    fileUrl
                }
            }
        }
    }

`;

export const FIND_PAYSLIPS = (gql: any) => gql`
    query findPayslips($offset: String, $limit: Int, $sortField: String, $sortOrder: String, $year: Int) {
        findPayslips(offset: $offset, limit: $limit, sortField:$sortField, sortOrder: $sortOrder, year: $year ) {
            cursor
            count
            items {
                id
                tenant
                tenantName
                code
                date
                employee
                employeeEmail
                employeeFirstName
                employeeLastName
                employeePhone
                externalId
                mainFile {
                    available
                    url
                }   
            }
        }
    }
`;
export const FIND_CINVOICES = (gql: any) => gql`
    query findCinvoices($offset: String, $limit: Int) {
        findCinvoices(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                tenant
                tenantCode
                tenantName
                customer
                customerCode
                customerName
                project
                projectCode
                projectName
                price
                description
                externalId
            }
        }
    }
`;
export const FIND_CPERIODS = (gql: any) => gql`
    query findCperiods($offset: String, $limit: Int) {
        findCperiods(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                customer
                customerCode
                customerName 
                project 
                projectCode
                projectName
                tenant 
                tenantCode
                tenantName
               
            }
        }
    }
`;
export const FIND_CPAYMENTS = (gql: any) => gql`
    query findCpayments($offset: String, $limit: Int) {
        findCpayments(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                createdAt
                updatedAt
                customer
                customerName
                customerCode
                project
                projectName
                projectCode
                tenant 
                tenantName
                tenantCode
               
            }
        }
    }
`;
export const FIND_PROJECTS = (gql: any) => gql`
    query findProjects($offset: String, $limit: Int) {
        findProjects(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                tenant
                tenantName
                customer
                customerName
                name
                code
            }
        }
    }
`;

export const UPDATE_NOTIFICATION = (gql: any) => gql`
    mutation updateNotification($id: ID!, $data: UpdateNotificationInput!) {
        updateNotification(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_USER = (gql: any) => gql`
    mutation updateUser($data: UpdateUserInput!) {
        updateUser(data: $data) {
            id
        }
    }
`;
export const UPDATE_EMPLOYEE = (gql: any) => gql`
    mutation updateEmployee($id: ID!, $data: UpdateEmployeeInput!) {
        updateEmployee(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_PERIOD = (gql: any) => gql`
    mutation updatePeriod($id: ID!, $data: UpdatePeriodInput!) {
        updatePeriod(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_EINVOICE = (gql: any) => gql`
    mutation updateEinvoice($id: ID!, $data: UpdateEinvoiceInput!) {
        updateEinvoice(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_REPORT = (gql: any) => gql`
    mutation updateReport($id: ID!, $data: UpdateReportInput!) {
        updateReport(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_CUSTOMER = (gql: any) => gql`
    mutation updateCustomer($id: ID!, $data: UpdateCustomerInput!) {
        updateCustomer(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_CONTRACT = (gql: any) => gql`
    mutation updateContract($id: ID!, $data: UpdateContractInput!) {
        updateContract(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_SIMULATION = (gql: any) => gql`
    mutation updateSimulation($id: ID!, $data: UpdateSimulationInput!) {
        updateSimulation(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_PROJECT = (gql: any) => gql`
    mutation updateProject($id: ID!, $data: UpdateProjectInput!) {
        updateProject(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_CINVOICE = (gql: any) => gql`
    mutation updateCinvoice($id: ID!, $data: UpdateCinvoiceInput!) {
        updateCinvoice(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_CPERIOD = (gql: any) => gql`
    mutation updateCperiod($id: ID!, $data: UpdateCperiodInput!) {
        updateCperiod(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_CPAYMENT = (gql: any) => gql`
    mutation updateCpayment($id: ID!, $data: UpdateCpaymentInput!) {
        updateCpayment(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_PAYSLIP = (gql: any) => gql`
    mutation updatePayslip($id: ID!, $data: UpdatePayslipInput!) {
        updatePayslip(id: $id, data: $data) {
            id
        }
    }
`;

export const DELETE_NOTIFICATION = (gql: any) => gql`
    mutation deleteNotification($id: ID!) {
        deleteNotification(id: $id) {
            id
        }
    }
`;

export const DELETE_EMPLOYEE = (gql: any) => gql`
    mutation deleteEmployee($id: ID!) {
        deleteEmployee(id: $id) {
            id
        }
    }
`;
export const DELETE_PERIOD = (gql: any) => gql`
    mutation deletePeriod($id: ID!) {
        deletePeriod(id: $id) {
            id
        }
    }
`;
export const DELETE_EINVOICE = (gql: any) => gql`
    mutation deleteEinvoice($id: ID!) {
        deleteEinvoice(id: $id) {
            id
        }
    }
`;
export const DELETE_REPORT = (gql: any) => gql`
    mutation deleteReport($id: ID!) {
        deleteReport(id: $id) {
            id
        }
    }
`;

export const DELETE_EXPENSE = (gql: any) => gql`
    mutation deleteExpense($id: ID!) {
        deleteExpense(id: $id) {
            id
        }
    }
`;

export const DELETE_CUSTOMER = (gql: any) => gql`
    mutation deleteCustomer($id: ID!) {
        deleteCustomer(id: $id) {
            id
        }
    }
`;
export const DELETE_CONTRACT = (gql: any) => gql`
    mutation deleteContract($id: ID!) {
        deleteContract(id: $id) {
            id
        }
    }
`;
export const DELETE_SIMULATION = (gql: any) => gql`
    mutation deleteSimulation($id: ID!) {
        deleteSimulation(id: $id) {
            id
        }
    }
`;
export const DELETE_PROJECT = (gql: any) => gql`
    mutation deleteProject($id: ID!) {
        deleteProject(id: $id) {
            id
        }
    }
`;
export const DELETE_CINVOICE = (gql: any) => gql`
    mutation deleteCinvoice($id: ID!) {
        deleteCinvoice(id: $id) {
            id
        }
    }
`;
export const DELETE_CPERIOD = (gql: any) => gql`
    mutation deleteCperiod($id: ID!) {
        deleteCperiod(id: $id) {
            id
        }
    }
`;
export const DELETE_CPAYMENT = (gql: any) => gql`
    mutation deleteCpayment($id: ID!) {
        deleteCpayment(id: $id) {
            id
        }
    }
`;
export const DELETE_PAYSLIP = (gql: any) => gql`
    mutation deletePayslip($id: ID!) {
        deletePayslip(id: $id) {
            id
        }
    }
`;

export const DELETE_USER = (gql: any) => gql`
    mutation deleteUser($id: ID!) {
        deleteUser(id: $id) {
            id
        }
    }
`;

export const DELETE_BUSINESSDAY = (gql: any) => gql`
    mutation deleteBusinessDay($id: ID!) {
        deleteBusinessDay(id: $id) {
            id
        }
    }
`;


