import {red} from '@material-ui/core/colors';
import buildMenus from "@genstackio/react-admin-core/lib/utils/buildMenus";
import crudRoutes from "@genstackio/react-admin-core/lib/utils/crudRoutes";
import buildBreadcrumbs from "@genstackio/react-admin-core/lib/utils/buildBreadcrumbs";
import buildUploader from "@genstackio/react-admin-core/lib/utils/buildUploader";
import buildLists from "@genstackio/react-admin-core/lib/utils/buildLists";
import * as types from './types';

export const app = {
    prefix: 'g8w_employee',
    endpoint: process.env.REACT_APP_API_ENDPOINT,
    locales: ['fr-FR'],
    requiredRoles: ['user'],
};

export const apiOptions = {
    timeout: 15000,
};

export const logos = {
    logo: {url: '/logo-GC.png', alt: 'G8W'},
    logoFull: {url: '/logo-GC.png', alt: 'G8W'},
};

export const actualYear =  new Date().getFullYear();

export const dataArrayFormat = (data: any) => {
    if (data === undefined) { return [] };
    return [].concat(data).sort((a: any, b: any) => a.dateStart < b.dateStart ? 1 : -1);
}

export const transformDateString = (dateString:string):string => {
    const date = new Date(dateString);
    date.setDate(2);
    const ds = date.toLocaleDateString('fr-FR',{year:'numeric',month:'numeric'}).split('/').reverse().join('-');
    return ds;
}

export const getYearsRange:any = (year: number): number[] => {
    return Array.from(
      { length: actualYear - year + 1 },
      (_, i) => actualYear - i
    ).reduce<number[]>((acc, val) => acc.concat(val), []);
  }

export const getNetPayBeforeITFromRawSalary = async (raw_salary:number) => {
    let result;
   await fetch(`${process.env.REACT_APP_PAYSLIP_API_ENDPOINT}/getNetPayBeforeITFromRawSalary`,{
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
            body: JSON.stringify({
                raw_salary:raw_salary
            }),
        }).then((response:any) => response.json())
        .then((data:any) => {
            result = data.value
            return data.value;
        })
        .catch((err:any) => {
            console.log(err)
        })
        return result;
}

export const generateStatementForecast = async (period:Period,forecastLengthInMonths:number) => {
    let result;
   await fetch(`${process.env.REACT_APP_PAYSLIP_API_ENDPOINT}/generatePeriodBasedForecast`,{
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
            body: JSON.stringify({
                period: period,
                forecastLengthInMonths: forecastLengthInMonths
            }),
        }).then((response:any)=> response.json())
        .then((data:any) => {
            result = data.periodsArray
        })
        .catch((err:any) => {
            console.log(err)
        })
        return result;
}


// const t = useTranslation();
//@TODO: use translation 
export const statementLabels = [{name:"period",label:'Mois'},{name:"contractName",label:"Contrat"},{name:"contractRate",label:"TJM Consultant"},
        {name:"periodPercent",label:"Taux de gestion GW"},{name:"workedDay",label:"Nb de jours facturés"},{name:"periodTurnover",label:"Chiffre d'affaires"},
        {name:"gwFee",label:"Frais de gestion gw"},{name:"internalFees",label:"Frais pro"},{name:"commercialFee",label:"Commission apporteur d'affaire"},
        {name:"baseAccount",label:"Compte de réserve brut"},{name:"employerContributions",label:"Charges patronales"},{name:"employeeContributions",label:"Charges Salariales"},
        {name:"taxedSalary",label:"Salaire net avant impôts"},{name:"incomeTaxes",label:"Impôts sur le revenu"},{name:"salary",label:"Salaire versé"},{name:"peeAmount",label:"Pee"},
        {name:"perAmount",label:"Per"},{name:"expenses",label:"Dépenses"},{name:"earnings",label:"Autre Gains"}
];
export const mobileStatementLabels = [
    {name:"period",label:"Mois"},{name:"contractName",label:"Contrat"},{name:"contractRate",label:"TJM Consultant"},{name:"workedDay",label:"Jours facturés"},{name:"periodTurnover",label:"Chiffre d'affaires"},
    {name:"baseAccount",label:"Cpte de réserve"},{name:"salary",label:"Salaire versé"},{name:'expenses',label:'Dépenses'}
];
export const statementSimulationLabels = ["Mois","TJM Consultant","Taux de gestion GW","Nb de jours facturés","Chiffre d'affaires","Frais de gestion gw","Frais pro","Compte de réserve brut","Charges patronales","Charges Salariales","Salaire net avant impôts","Pee","Per","Autre"]

export const color = (val: number, type: string) => {
    const mobile = [
        "text-primary","bg-primary text-clear","bg-primary text-clear","bg-primary text-clear",
        "text-success","text-blue bg-lightblue font-bold","bg-lightgreen text-green font-bold",
        "bg-primary text-clear",
    ];
    const desktop = [
        "text-clear bg-primary","text-primary","text-clear bg-primary","text-green","text-clear bg-primary",
        "text-primary","bg-lightgreen text-green font-bold","text-lightred","bg-primary text-lightred",
        "text-lightred","bg-lightblue text-blue font-bold","text-lightred","bg-primary text-lightred",
        "text-green","bg-primary text-lightred","bg-lightgreen text-green font-bold","text-clear bg-primary",
        "text-primary","text-clear bg-primary",
    ];
    const simulation = [
        "text-primary","text-primary","text-green","text-green","text-primary","bg-primary text-clear",
        "bg-primary text-clear","bg-primary text-clear","bg-lightgreen text-green font-bold","text-lightred",
        "text-lightred","bg-primary text-lightred","bg-primary text-lightred","text-blue bg-lightblue font-bold"
    ];
    const map:any = { mobile, desktop, simulation };
    return map[type][val];
};

export const currency = (val: number, type: string) => {
    const mobile = ['', '', '€','J','€','€','€'];
    const desktop = ['', '','','€', '%', 'J', '€', '€', '€','€','€','€','€','€','€','€','€','€'];
    const simulation = ['', '', '%', ' Jours', '€', '€', '€', '€', '€', '€', '€', '€', '€', '', '€', '€', '€', '€', '€'];
    const map:any = { mobile, desktop, simulation };
    return map[type][val];
};

export const nFmt = (val:number) => {
    return val?.toLocaleString('fr-FR') || 0;
};

export const uploader = buildUploader(process.env.REACT_APP_UPLOADS_ENDPOINT);
export const theme = {
    tailwind: {},
    mui: {
        palette: {
            primary: {
                main: 'rgb(16,23,96)',
            },
            secondary: {
                main: 'rgb(250,16,105)',
            },
            error: {
                main: red.A400,
            },
            background: {
                default: '#FFFFFF',
            },
        },
    },
    theme: '',
};
export const ambiance = {
    sectionHeaderCorner: 'rounded-xxsmall',
    menuItemCorner: 'rounded-xsmall',
    menuFoldCorner: 'rounded-xxxsmall',
    buttonCorner: 'rounded-xsmall',
};
export const routes = [
    {path: "/user/forgot-password", screen: 'forgot_password', secured: false},
    {path: '/', exact: false, screen: 'app', routes: [
            {path: '/', screen: 'home', exact: true},
            {path: '/missions', screen: 'contracts', exact: true},
            {path: '/payslips', screen: 'payslips', exact: true},
            {path: '/statements', screen: 'statements', exact: true},
            {path: '/files', screen: 'files', exact: true},
            {path: '/', exact: false, screen: 'crud_app', routes: [
                    ...crudRoutes(types),
                    {path: "/user/profile/edit", screen: 'user/edit_current_user'},
                    {path: "/user/profile", screen: 'user/user_profile'},
                    {path: "/simulation/new", screen: 'simulation'},
                    {path: "/report/new", screen: 'report'},
                    {path: "/businessDay/new", screen: 'businessDay'},
                    {screen: 'not_found', exact: false, secured: false},
                ]},
            {screen: 'not_found', exact: false, secured: false},
        ]},
    {screen: 'not_found', exact: false, secured: false},
];
export const lists = buildLists(types);
export const breadcrumbs = buildBreadcrumbs(types, ({t}: any) => ({
    'user-profile': [
        {label: t('screen_home_label'), target: '/'},
        {label: t('screen_user_profile_label'), target: '/user/profile'},
    ],
    default: [
        {label: t('screen_home_label'), target: '/'},
    ],
}));
export const menus = buildMenus(types, {
    main: {
        tools:  {
            items:({t}: {t: Function}) =>[
                {label: t('Nouvelle feuille de temps'), type: 'item',icon: 'fa-plus', activePrefix: '/report/new',  target: '/report/new'},
                {label: t('Nouvelle demande de congés'), type: 'item',icon: 'fa-plus', activePrefix: '/businessDay/new',  target: '/businessDay/new'},
            ]
        },
        files: {
            items: ({t}: {t: Function}) => [
                {label: t('Mes Missions'), type: 'item',icon: 'fa-user-tie', activePrefix: '/missions',  target: '/missions'},
                {label: t('Mes Bulletins'), type: 'item',icon: 'fa-file', activePrefix: '/payslips',  target: '/payslips'},
                {label: t('Mes Décomptes'), type: 'item',icon: 'fa-folder-plus', activePrefix: '/statements',  target: '/statements'},
                {label: t('Mes Documents'), type: 'item', icon:'fa-file', activePrefix:'/files', target:'/files'}
            ],
        },
        settings:  {},
    },
    user: {
        '-': {
            items: ({t}: {t: Function}) => [
                {label: t('user_menu_profile_label'), type: 'item', target: 'user/profile'},
            ],
        }
    }
});

interface Period {
    employee?: String
    id?: String
    tenant?: String
    contract?: String
    contractName?: String
    contractRate?: number
    tenantName?: String
    dateStart?: number
    dateEnd?: number
    project?: String
    projectCode?: String
    customerName?: String
    externalId?: String
    workedDay?: number
    periodPercent?: number
    periodTurnover?: number
    internalFees?: number
    periodExpense?: number
    periodChargeProvided?: number
    baseAccount?: number
    taxedSalary?: number
    employerContributions?: number
    employeeContributions?: number
    salary?: number
    peeAmount?: number
    perAmount?: number
    incomeTaxes?: number
    locked?: boolean
    commercialFee?: number
    craUrl?: String
    payslipUrl?: String
    expenses?: [ ]
}
export * as queries from './queries';
export {default as fields} from './fields';
export {default as icons} from './icons';
export {default as translations} from './translations';
export {default as importers} from './importers';