const importers = {
    screen: [({path}: any) => import(`./screens/${path}Screen`), ['home','contracts', 'user/user_profile','payslips','statements','files','simulation','denied','report','businessDay','login']],
    body: [({path}: any) => import(`./bodies/${path}Body`),
        [
            'notification',
        ],
    ],
    tab: [
        ({path}: any) => import(`./tabs/${path}Tab`),
        [
            'organization/infos', 'organization/users',
        ],
    ],
    toolbar: [
        ({path}: any) => import(`./toolbars/${path}Toolbar`),
        [
            'app', 'notification', 'simulation',
        ],
    ],
};

export default importers;