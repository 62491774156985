import {faSquareCheck} from "@fortawesome/free-solid-svg-icons/faSquareCheck";
import {faEdit} from "@fortawesome/free-regular-svg-icons/faEdit";
import {faMoon} from "@fortawesome/free-regular-svg-icons/faMoon";
import {faLightbulb} from "@fortawesome/free-regular-svg-icons/faLightbulb";
import {faStar} from "@fortawesome/free-regular-svg-icons/faStar";
import {faStar as faStarSolid} from "@fortawesome/free-solid-svg-icons/faStar";
import {faExpand} from "@fortawesome/free-solid-svg-icons/faExpand";
import {faComment} from "@fortawesome/free-regular-svg-icons/faComment";
import {faCalendarDays} from "@fortawesome/free-regular-svg-icons/faCalendarDays";
import {faComment as faCommentSolid} from "@fortawesome/free-solid-svg-icons/faComment";
import {faBell} from "@fortawesome/free-regular-svg-icons/faBell";
import {faBell as faBellSolid} from "@fortawesome/free-solid-svg-icons/faBell";
import {faCartShopping} from "@fortawesome/free-solid-svg-icons/faCartShopping";
import {faCartArrowDown} from "@fortawesome/free-solid-svg-icons/faCartArrowDown";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons/faMagnifyingGlass";
import {faUsers} from "@fortawesome/free-solid-svg-icons/faUsers";
import {faUserTag} from "@fortawesome/free-solid-svg-icons/faUserTag";
import {faUserTie} from "@fortawesome/free-solid-svg-icons/faUserTie";
import {faBuilding} from "@fortawesome/free-solid-svg-icons/faBuilding";
import {faScaleBalanced} from "@fortawesome/free-solid-svg-icons/faScaleBalanced";
import {faDice} from "@fortawesome/free-solid-svg-icons/faDice";
import {faMoneyBillWave} from "@fortawesome/free-solid-svg-icons/faMoneyBillWave";
import {faInfo} from "@fortawesome/free-solid-svg-icons/faInfo";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons/faPenToSquare";
import {faEye} from "@fortawesome/free-regular-svg-icons/faEye";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {faTicket} from "@fortawesome/free-solid-svg-icons/faTicket";
import {faUpRightFromSquare} from "@fortawesome/free-solid-svg-icons/faUpRightFromSquare";
import {faImage} from "@fortawesome/free-solid-svg-icons/faImage";
import {faVideo} from "@fortawesome/free-solid-svg-icons/faVideo";
import {faFileAudio} from "@fortawesome/free-solid-svg-icons/faFileAudio";
import {faPlay} from "@fortawesome/free-solid-svg-icons/faPlay";
import {faPause} from "@fortawesome/free-solid-svg-icons/faPause";
import {faBan} from "@fortawesome/free-solid-svg-icons/faBan";
import {faArrowCircleLeft} from "@fortawesome/free-solid-svg-icons/faArrowCircleLeft";
import {faHandshakeSimple} from "@fortawesome/free-solid-svg-icons/faHandshakeSimple";
import {faAward} from "@fortawesome/free-solid-svg-icons/faAward";
import {faArrowRotateRight} from "@fortawesome/free-solid-svg-icons/faArrowRotateRight";
import {faBox} from "@fortawesome/free-solid-svg-icons/faBox";
import {faFileCode} from "@fortawesome/free-solid-svg-icons/faFileCode";
import {faArrowUp} from "@fortawesome/free-solid-svg-icons/faArrowUp";
import {faArrowDown} from "@fortawesome/free-solid-svg-icons/faArrowDown";
import {faKey} from "@fortawesome/free-solid-svg-icons/faKey";
import {faToolbox} from "@fortawesome/free-solid-svg-icons/faToolbox";
import {faGift} from "@fortawesome/free-solid-svg-icons/faGift";
import {faFolderPlus} from "@fortawesome/free-solid-svg-icons/faFolderPlus";
import {faHandHoldingDollar} from "@fortawesome/free-solid-svg-icons/faHandHoldingDollar";
import {faKeyboard} from "@fortawesome/free-solid-svg-icons/faKeyboard";
import {faBullhorn} from "@fortawesome/free-solid-svg-icons/faBullhorn";
import {faBookOpen} from "@fortawesome/free-solid-svg-icons/faBookOpen";
import {faTrophy} from "@fortawesome/free-solid-svg-icons/faTrophy";
import {faPalette} from "@fortawesome/free-solid-svg-icons/faPalette";
import {faTag} from "@fortawesome/free-solid-svg-icons/faTag";
import {faPaperPlane} from "@fortawesome/free-solid-svg-icons/faPaperPlane";
import {faFileImport} from "@fortawesome/free-solid-svg-icons/faFileImport";
import {faClock} from "@fortawesome/free-solid-svg-icons/faClock";
import {faFile} from "@fortawesome/free-solid-svg-icons/faFile";
import {faChartColumn} from "@fortawesome/free-solid-svg-icons/faChartColumn";
import {faDownload} from "@fortawesome/free-solid-svg-icons/faDownload";
import {faEnvelope} from "@fortawesome/free-regular-svg-icons/faEnvelope";

const icons = {
    fa: [
        faSquareCheck,
        faEdit,
        faMoon,
        faLightbulb,
        faStar,
        faStarSolid,
        faExpand,
        faCalendarDays,
        faComment,
        faCommentSolid,
        faBell,
        faBellSolid,
        faCartShopping,
        faCartArrowDown,
        faMagnifyingGlass,
        faUsers,
        faUserTag,
        faUserTie,
        faBuilding,
        faScaleBalanced,
        faDice,
        faMoneyBillWave,
        faInfo,
        faTrash,
        faPenToSquare,
        faEye,
        faPlus,
        faTicket,
        faUpRightFromSquare,
        faImage,
        faVideo,
        faFileAudio,
        faPlay,
        faPause,
        faBan,
        faArrowCircleLeft,
        faHandshakeSimple,
        faAward,
        faArrowRotateRight,
        faBox,
        faFileCode,
        faArrowUp,
        faArrowDown,
        faKey,
        faToolbox,
        faGift,
        faFolderPlus,
        faHandHoldingDollar,
        faKeyboard,
        faBullhorn,
        faBookOpen,
        faTrophy,
        faPalette,
        faTag,
        faPaperPlane,
        faFileImport,
        faClock,
        faFile,
        faChartColumn,
        faDownload,
        faEnvelope
    ],
};

export default icons;