const t = {
    icon: 'fa-money',
    menus: {
        main: {section: 'files', priority: 30},
    },
    display: {
        properties: [
            'id', 'customer', 'project','price','description', 'tenant','externalId',
            'url','locked'
        ],
    },
    list: {
        props: {
            search: false,
            searchSwitch: false,
        },
        icon: 'fa-money-bill-wave',
        columns: [
            {id: 'cost', label: 'column_cost_label', format:(v:any) => `${v/100}€`},
            {id: 'name', label: 'column_name_label'},
            {id: 'description', label: 'column_description_label'},
            {id: 'url', label: 'column_url_label'},
        ],
        globalActions: [],
        actions: ['display'],
    },
};

export default t;