const t = {
    icon: 'fa-file',
    display: {
        properties: [
            'id', 'customer', 'project','price','description', 'tenant','externalId',
            'url','locked'
        ],
    }
};

export default t;