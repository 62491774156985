const t = {
    icon: 'fa-user-tie',
    display: {
        toolbar: true,
        properties: [
            'id', ['code'], 'customer','project', 'employee', ['rate' ]
        ],
    }
};

export default t;